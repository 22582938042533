import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFound = ({ location }) => (
  <Layout>
    <SEO pagetitle="ページがみつかりませんでした" pagepath={location.pathname} />
    <h1 style={{ fontSize: "47px", padding: "22vh 0 5vh", textAlign: "center" }}>404<br /> Not Found</h1>
    <h2 style={{ padding: "10vh 0 17vh", textAlign: "center" }}>お探しのページがみつかりませんでした</h2>
  </Layout>
)

export default NotFound